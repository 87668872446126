<template>
  <div class="shelve_goods_management">
    <el-form class="zwx-form" key="goodsForm" ref="goodsForm" :model="goodsForm" label-position="right" @submit.native.prevent>
      <div class="self-adapting-condition">
        <!--                <el-form-item label="类别：">
                    <el-checkbox-group class="zwx-checkbox-group" v-model="goodsForm.goodsTypeList">
                        <el-checkbox class="zwx-checkbox" v-for="item in goodsTypeSimCodeList" :label="item.codeNo"
                                     :key="item.rid">{{ item.codeName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>-->
        <!--                <el-form-item label="课程类型：">
                  <cascader-options-multi ref="cascader" v-model="msForm.economicCodes"
                                          :options='economicCodeList' dataNameFiled='codeName' dataCodeFiled='codeNo'
                                          dataUpCodeFiled='children' codeLevelNo='codeLevelNo'
                                          width='200px' :showFullTag='false' :collapsetags='true'
                  ></cascader-options-multi>
                </el-form-item>-->
        <el-form-item label="商品名称：">
          <el-input class="zwx-input" v-model.trim="goodsForm.goodsName" style="width: 200px !important" placeholder="" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label="上架日期：">
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="goodsForm.merchandisngDateStart" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noBigDate($data['goodsForm'], 'merchandisngDateEnd', true)" placeholder="开始日期" />
          -
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="goodsForm.merchandisngDateEnd" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noSmallDate($data['goodsForm'], 'merchandisngDateStart', true)" placeholder="结束日期" />
        </el-form-item>

        <el-form-item label="上架状态：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="goodsForm.merchandisngStateArr">
            <el-checkbox class="zwx-checkbox" :label="1">上架中</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">暂存</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="-1">已下架</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div style="clear: both" />
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryShelveGoodsList(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">
        添加
      </el-button>
    </div>

    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="uuid" :data="goodsForm.tableList" border stripe tooltip-effect="light">
      <!--            <el-table-column prop="goodsTypeName" label="类别" width="120" header-align="center"
                             align="center"></el-table-column>-->
      <el-table-column prop="goodsName" label="商品名称" width="300" header-align="center" align="left"></el-table-column>
      <!-- <el-table-column prop="goodsNo" label="商品编号" width="190" header-align="center" align="center" show-overflow-tooltip></el-table-column> -->
      <el-table-column prop="price" v-if="unitNature != 10180003" label="原价(元)" width="120" header-align="center" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="salePrice" v-if="unitNature != 10180003" label="售价(元)" width="120" header-align="center" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="expiryDate" label="有效期限(天)" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.expiryDate || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="merchandisngState" label="上架状态" width="110" header-align="center" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.merchandisngState === 1">
            <div class="zwx-circle-text zwx-circle-complete">上架中</div>
          </template>
          <template v-if="scope.row.merchandisngState === 0">
            <div class="zwx-circle-text zwx-circle-normal">暂存</div>
          </template>
          <template v-if="scope.row.merchandisngState === -1">
            <div class="zwx-circle-text zwx-circle-finish">已下架</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="merchandisngDate" label="上架日期" width="180" header-align="center" align="center" :formatter="dateFormatter">
        <template slot-scope="scope">
          <span>{{ scope.row.merchandisngDate || '-' }}</span>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" min-width="150" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.merchandisngState === 0 || scope.row.merchandisngState === -1" @click="edit(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.merchandisngState === 0 || scope.row.merchandisngState === -1" @click="changeState(scope.row.uuid, 1)"><span>上架</span></el-button>
          <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text" v-if="scope.row.merchandisngState === 1" @click="changeState(scope.row.uuid, -1)">下架</el-button>
          <el-button class="zwx-button zwx-button-text-26  el-button--danger" type="text" v-if="scope.row.merchandisngState === 0" @click="deleteGoods(scope.row.uuid)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination :parentPage="goodsForm.currentPage" :pageSize="goodsForm.pageSize" :total="goodsForm.total" @currentChange="queryShelveGoodsList" />
  </div>
</template>

<script>
export default {
  name: 'ShelveGoodsManagement',
  data() {
    return {
      api: this.$store.state.api,
      goodsForm: {
        goodsTypeList: [],
        goodsName: '',
        merchandisngDateStart: '',
        merchandisngDateEnd: '',
        merchandisngStateArr: [1],
        tableList: [],
        currentPage: 1,
        pageSize: 18,
        total: 0,
      },
      goodsTypeSimCodeList: [],
      unitNature:null

    }
  },
  activated() {
    if (this.$route.params.fresh) this.queryShelveGoodsList(1)
  },
  mounted() {
    /**
     * 
    this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('4019', data => {
      this.goodsTypeSimCodeList = data['4019']
      this.$nextTick(() => {
        this.queryShelveGoodsList(1)
      })
    })
     */
     this.unitNature =window.top.vm.$store.state.config.unitNature
    this.queryShelveGoodsList(1)
  },
  methods: {
    dateFormatter(row, column, cellValue) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    queryShelveGoodsList(currentPage) {
      this.goodsForm.currentPage = currentPage
      let data = {
        currentPage,
        goodsName: this.goodsForm.goodsName,
        merchandisngDateStart: this.goodsForm.merchandisngDateStart,
        merchandisngDateEnd: this.goodsForm.merchandisngDateEnd,
        merchandisngStateArr: this.goodsForm.merchandisngStateArr,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getYwdShelveGoodsList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.goodsForm.tableList = data.resultList
            this.goodsForm.pageSize = data.pageSize
            this.goodsForm.total = data.resultCount
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    add() {
      this.$router.push({ name: 'product.create', params: { type: 'add' } })
    },
    edit(row) {
      this.$router.push({ name: 'product.edit', params: { row: row, type: 'edit' } })
    },
    changeState(uuid, state) {
      let tips = state === -1 ? '下架' : '上架'
      let url = state === -1 ? '/training/platform/unShelveGoods-1' : '/training/platform/shelveGoods-1'
      this.$system.msgbox('', '提示', '是否确定' + tips + '？', '确定', '取消', () => {
        let data = {
          uuid: uuid,
        }
        this.$system.post(
          this.api + url,
          data,
          true,
          true,
          data => {
            if (data.type === '00') {
              this.$system.notify('成功', tips + '成功', 'success')
              this.queryShelveGoodsList(1)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
    deleteGoods(uuid) {
      this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
        let data = {
          uuid: uuid,
        }
        // eslint-disable-next-line no-debugger
        this.$system.post(
          this.api + '/training/platform/deleteShelveGoods-1',
          data,
          true,
          true,
          data => {
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.queryShelveGoodsList(1)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
  },
}
</script>

<style scoped></style>
