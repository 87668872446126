var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shelve_goods_management" },
    [
      _c(
        "el-form",
        {
          key: "goodsForm",
          ref: "goodsForm",
          staticClass: "zwx-form",
          attrs: { model: _vm.goodsForm, "label-position": "right" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "self-adapting-condition" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称：" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: { placeholder: "", clearable: "", maxlength: "50" },
                    model: {
                      value: _vm.goodsForm.goodsName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.goodsForm,
                          "goodsName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "goodsForm.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上架日期：" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.$validate.noBigDate(
                        _vm.$data["goodsForm"],
                        "merchandisngDateEnd",
                        true
                      ),
                      placeholder: "开始日期"
                    },
                    model: {
                      value: _vm.goodsForm.merchandisngDateStart,
                      callback: function($$v) {
                        _vm.$set(_vm.goodsForm, "merchandisngDateStart", $$v)
                      },
                      expression: "goodsForm.merchandisngDateStart"
                    }
                  }),
                  _vm._v(" - "),
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.$validate.noSmallDate(
                        _vm.$data["goodsForm"],
                        "merchandisngDateStart",
                        true
                      ),
                      placeholder: "结束日期"
                    },
                    model: {
                      value: _vm.goodsForm.merchandisngDateEnd,
                      callback: function($$v) {
                        _vm.$set(_vm.goodsForm, "merchandisngDateEnd", $$v)
                      },
                      expression: "goodsForm.merchandisngDateEnd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上架状态：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.goodsForm.merchandisngStateArr,
                        callback: function($$v) {
                          _vm.$set(_vm.goodsForm, "merchandisngStateArr", $$v)
                        },
                        expression: "goodsForm.merchandisngStateArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("上架中")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 0 } },
                        [_vm._v("暂存")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: -1 } },
                        [_vm._v("已下架")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticStyle: { clear: "both" } })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "independent-button-row" },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.queryShelveGoodsList(1)
                }
              }
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { icon: "el-icon-plus" },
              on: { click: _vm.add }
            },
            [_vm._v(" 添加 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          attrs: {
            "row-key": "uuid",
            data: _vm.goodsForm.tableList,
            border: "",
            stripe: "",
            "tooltip-effect": "light"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "goodsName",
              label: "商品名称",
              width: "300",
              "header-align": "center",
              align: "left"
            }
          }),
          _vm.unitNature != 10180003
            ? _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: "原价(元)",
                  width: "120",
                  "header-align": "center",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _vm.unitNature != 10180003
            ? _c("el-table-column", {
                attrs: {
                  prop: "salePrice",
                  label: "售价(元)",
                  width: "120",
                  "header-align": "center",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "expiryDate",
              label: "有效期限(天)",
              width: "120",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.expiryDate || "-"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "merchandisngState",
              label: "上架状态",
              width: "110",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.merchandisngState === 1
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "zwx-circle-text zwx-circle-complete"
                            },
                            [_vm._v("上架中")]
                          )
                        ]
                      : _vm._e(),
                    scope.row.merchandisngState === 0
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "zwx-circle-text zwx-circle-normal"
                            },
                            [_vm._v("暂存")]
                          )
                        ]
                      : _vm._e(),
                    scope.row.merchandisngState === -1
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "zwx-circle-text zwx-circle-finish"
                            },
                            [_vm._v("已下架")]
                          )
                        ]
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "merchandisngDate",
              label: "上架日期",
              width: "180",
              "header-align": "center",
              align: "center",
              formatter: _vm.dateFormatter
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.merchandisngDate || "-"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              "min-width": "150",
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.merchandisngState === 0 ||
                    scope.row.merchandisngState === -1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    scope.row.merchandisngState === 0 ||
                    scope.row.merchandisngState === -1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.changeState(scope.row.uuid, 1)
                              }
                            }
                          },
                          [_c("span", [_vm._v("上架")])]
                        )
                      : _vm._e(),
                    scope.row.merchandisngState === 1
                      ? _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 zwx-button-danger",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.changeState(scope.row.uuid, -1)
                              }
                            }
                          },
                          [_vm._v("下架")]
                        )
                      : _vm._e(),
                    scope.row.merchandisngState === 0
                      ? _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 el-button--danger",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.deleteGoods(scope.row.uuid)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("base-pagination", {
        attrs: {
          parentPage: _vm.goodsForm.currentPage,
          pageSize: _vm.goodsForm.pageSize,
          total: _vm.goodsForm.total
        },
        on: { currentChange: _vm.queryShelveGoodsList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }