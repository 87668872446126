<template>
  <div class="pe-record-management">
    <el-form class="zwx-form" key="peRecordListForm" ref="peRecordListForm" label-position="right" :model="peRecordListForm" @submit.native.prevent>
      <div class="condition-row">
        <el-form-item label="员工姓名：" label-width="70px">
          <el-input class="zwx-input" placeholder="请输入" style="width: 160px!important;" v-model.trim="peRecordListForm.staffNameLike" clearable />
        </el-form-item>
        <el-form-item label="体检编号：" label-width="70px">
          <el-input class="zwx-input" placeholder="请输入" style="width: 160px!important;" v-model.trim="peRecordListForm.peCodeLike" :clearable="true" />
        </el-form-item>
        <el-form-item label="身份证号：" label-width="70px">
          <el-input class="zwx-input" placeholder="请输入" style="width: 160px!important;" v-model.trim="peRecordListForm.cardNo" clearable />
        </el-form-item>
        <el-form-item label="体检机构：" label-width="70px">
          <el-input class="zwx-input" placeholder="请输入" style="width: 160px!important;" v-model.trim="peRecordListForm.organNameLike" :clearable="true" />
        </el-form-item>
      </div>
      <div class="condition-row" style="display:flex">
        <el-form-item label="体检时间：" label-width="70px">
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 198px !important;" v-model="peRecordListForm.peBeginDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noBigDate($data['peRecordListForm'], 'peEndDate', true)" placeholder="开始日期" />
          -
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 198px !important;" v-model="peRecordListForm.peEndDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noSmallDate($data['peRecordListForm'], 'peBeginDate', true)" placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="体检类型：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="peRecordListForm.peTypeList">
            <el-checkbox class="zwx-checkbox" v-for="item in peRecordListFormExp.peTypeOption" :label="item.id" :key="item.id">
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div class="condition-row" style="display:flex">
        <el-form-item label="在岗状态：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="peRecordListForm.postStateList">
            <el-checkbox class="zwx-checkbox" v-for="item in peRecordListFormExp.postStateOption" :label="item.codeNo" :key="item.codeNo">
              {{ item.codeName }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="condition-separator" />
        <el-form-item label="主检结论：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="peRecordListForm.conclusionCodeList">
            <el-checkbox class="zwx-checkbox" v-for="item in peRecordListFormExp.mainInspectionConclusionOption" :label="item.codeNo" :key="item.codeNo">
              {{ item.codeName }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryPeRecordList(1)">查询</el-button>
      <!-- <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">添加</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" @click="peRecordImport">导入</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-document" @click="showPeImportRecordDialog">
        导入记录
      </el-button> -->
    </div>
    <!-- 列表展示部分 -->
    <el-table ref="peRecordListTable" class="zwx-table sortable-table" row-key="rid" border stripe :data="peRecordListFormExp.tableList" @sort-change="changeTableSort">
      <el-table-column width="120" prop="staffName" label="姓名" header-align="center" align="center" sortable="'custom'">
        <template slot-scope="scope">
          {{ scope.row.staffName }}
          <!-- <i :class="scope.row.staffUid != undefined && scope.row.staffUid != '' ? '' : 'filled chis-icon-filled-overdue'" style="color:rgb(181 184 189)"></i> -->
        </template>
      </el-table-column>
      <el-table-column width="140" prop="peCode" label="体检编号" header-align="center" align="center" sortable="'custom'" />
      <el-table-column width="200" prop="cardNo" label="身份证号" header-align="center" align="center" />
      <el-table-column width="230" prop="organName" label="体检机构" header-align="center" align="left" sortable="'custom'" />
      <el-table-column width="200" prop="peDate" label="体检时间" header-align="center" align="center" sortable="'custom'">
        <template slot-scope="scope">
          {{ scope.row.peDate != undefined && scope.row.peDate != '' ? scope.row.peDate.replace('00:00:00','') : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="peType" label="体检类型" width="150" header-align="center" align="center" :formatter="peTypeFormatter" sortable="'custom'" />
      <el-table-column prop="postStateStr" label="在岗状态" width="150" header-align="center" align="center" sortable="'custom'" />
      <el-table-column label="主检结论" width="260" header-align="center" align="left">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.mainInspectionConclusionList" :key="item" :style="mainInspectionColor(item)">
            {{ item }}
            <i style="color:black" v-if="scope.row.mainInspectionConclusionList.length != 1 && index != scope.row.mainInspectionConclusionList.length - 1">、</i>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="160" header-align="left" align="left">
        <template slot-scope="scope">
          <div v-if="scope.row.dataSource == 1">
            <el-button class="zwx-button zwx-button-text-26" icon="el-icon-arrow-right" type="text" @click="detail(scope.row)">查看</el-button>
            <el-button class="zwx-button zwx-button-text-26" icon="el-icon-top-right" type="text" @click="editPeRecord(scope.row)">编辑</el-button>
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletePeRecord(scope.row)">删除</el-button>
          </div>
          <div v-else>
            <el-button class="zwx-button zwx-button-text-26" icon="el-icon-arrow-right" type="text" @click="detail(scope.row)">查看</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination class="normal-pagination" :parentPage="peRecordListForm.currentPage" :pageSize="peRecordListFormExp.pageSize" :total="peRecordListFormExp.total" @currentChange="queryPeRecordList" />

    <!--体检记录导入弹出框-->
    <base-dialog class="pe-record-import-dialog" ref="peRecordImportDialogRef" title="体检记录批量上传" :pagination="false" :ifDetermineDis="$zwxBase.verifyIsBlank(peRecordImportForm.importFileList)" @determine="peRecordImportDialogDetermine" @cancel="peRecordImportDialogCancel" @close="peRecordImportDialogClose">
      <el-form class="zwx-form" style="padding-bottom: 12px !important;" key="peRecordImportForm" ref="peRecordImportForm" :model="peRecordImportForm" :rules="peRecordImportFormRules" label-position="right">
        <el-form-item label-width="82px" label="体检机构：" prop="organName" style="margin-bottom: 20px !important;">
          <pe-organ-select class="organ-select" ref="peOrganSelectRef" @change="peOrganSelectChange" v-model="peRecordImportForm.organName" />
        </el-form-item>
        <file-upload ref="fileUploadRef" @showFileList="peRecordImportFileList" @success="peRecordImportFileUploadSuccess" accept=".xls,.xlsx,.XLS,.XLSX" :size="50 * 1024 * 1024" sizeInfo="50M" :drag="true" v-show="$zwxBase.verifyIsBlank(peRecordImportForm.importFileList)">
          <i class="el-icon-upload"></i>
          <div class="el-upload-text"><em>点击上传</em></div>
          <div class="el-upload-text" style="width: 360px;color: #777F89;font-size: 12px">
            请先下载导入模板，按表格内要求填写体检信息后再上传表格文件，支持格式：xls,xlsx,XLS,XLSX。
          </div>
        </file-upload>
        <div class="show-file-body" v-show="$zwxBase.verifyIsNotBlank(peRecordImportForm.importFileList)">
          <div class="show-file-box" @mouseenter="peRecordImportMouseenter" @mouseleave="peRecordImportMouseleave">
            <i class="colorful chis-icon-colorful-excel" />
            <div class="file-name-text">
              <div>{{ peRecordImportForm.fileName }}</div>
              <div style="margin-left: 5px">
                <span class="el-upload-list__item check" :style="peRecordImportForm.fileCheck ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                  <i class="el-icon-upload-success el-icon-circle-check"></i>
                </span>
                <span class="check" :style="peRecordImportForm.fileCheck ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                  <i class="el-icon-close" @click="delPeRecordImportFile"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div slot="footer">
        <el-button class="zwx-button zwx-button-text-32" style="margin-left: 20px" icon="el-icon-download" @click="downloadImportTemplate">下载导入模板</el-button>
        <span class="import-tip" v-if="importPeRecordNum">最多一次性导入{{ importPeRecordNum }}条数据</span>
      </div>
    </base-dialog>

    <!-- 体检导入记录查询弹框 -->
    <base-dialog class="import-record-query-dialog" ref="importRecordQueryDialogRef" title="查询导入记录" :ifDetermine="false" @cancel="cancelImportRecordQueryDialog" @close="closeImportRecordQueryDialog" :ifCancel="false" :pagination="true" @currentPage="queryImportRecordList" :currentPage="importRecordQueryForm.currentPage" :pageSize="importRecordQueryForm.pageSize" :total="importRecordQueryFormExp.total">
      <el-form class="zwx-form" style="padding: unset !important;" key="importRecordQueryForm" ref="importRecordQueryForm" :model="importRecordQueryForm" label-position="right" @submit.native.prevent>
        <div>
          <div class="edit-row">
            <el-form-item label="导入日期：" label-width="70px">
              <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 150px !important;" v-model="importRecordQueryForm.importDateStart" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noBigDate($data['importRecordQueryForm'], 'importDateEnd', true)" placeholder="开始日期" />
              -
              <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 150px !important;" v-model="importRecordQueryForm.importDateEnd" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noSmallDate($data['importRecordQueryForm'], 'importDateStart', true)" placeholder="结束日期" />
            </el-form-item>
            <el-form-item label="解析进度：" label-width="70px">
              <el-checkbox-group class="zwx-checkbox-group" v-model="importRecordQueryForm.analysisProcessList">
                <el-checkbox class="zwx-checkbox" :label="0">待解析</el-checkbox>
                <el-checkbox class="zwx-checkbox" :label="1">解析成功</el-checkbox>
                <el-checkbox class="zwx-checkbox" :label="2">存在错误数据</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryImportRecordList(1)">查询</el-button>
          </div>
        </div>
        <!-- 列表展示部分 -->
        <div class="import-record-query-body">
          <el-table class="zwx-table import-record-table" row-key="rid" :data="importRecordQueryFormExp.tableList" border stripe tooltip-effect="light">
            <el-table-column prop="importDate" label="导入日期" width="120" header-align="center" align="center" :formatter="dateFormatter"></el-table-column>
            <el-table-column prop="fileName" label="文件名称" min-width="150" header-align="center" align="left"></el-table-column>
            <el-table-column prop="analysisProcess" label="解析进度" width="140" header-align="center" align="center">
              <template slot-scope="scope">
                <span class="zwx-circle-text zwx-circle-underway" v-if="scope.row.analysisProcess == 0">待解析</span>
                <span class="zwx-circle-text zwx-circle-complete" v-if="scope.row.analysisProcess == 1">解析成功</span>
                <span class="zwx-circle-text zwx-circle-finish" v-if="scope.row.analysisProcess == 2">存在错误数据</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" header-align="left" align="left" width="100">
              <template slot-scope="scope">
                <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.analysisProcess == 2" @click="downloadErrorFile(scope.row)">错误报告</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'PERecordManagement',
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      //表单对象
      peRecordListForm: {
        //单位id
        employerUuid: '',
        // 页码
        currentPage: 1,
        staffNameLike: '',
        cardNo: '',
        ifExistence: false,
        organNameLike: '',
        peCodeLike: '',
        peBeginDate: '',
        peEndDate: '',
        peTypeList: [],
        conclusionCodeList: [],
        postStateList: [],
        orderBys: '',
        orderByName: '',
        sortType: '',
      },
      peRecordListFormExp: {
        //在岗状态
        postStateOption: [],
        //主检结论
        mainInspectionConclusionOption: [],
        //体检类型
        peTypeOption: [
          {
            id: 1,
            label: '职业健康体检',
          },
          {
            id: 2,
            label: '放射人员健康检查',
          },
          {
            id: 3,
            label: '福利体检',
          },
        ],
        tableList: [],
        pageSize: 18,
        total: 0,
      },
      peImportTempPath: this.$store.state.peImportTempPath,
      // 体检记录导入form
      peRecordImportForm: {
        organId: '',
        organName: '',
        organCreditCode: '',
        importFileList: [],
        fileName: '',
        filePath: '',
        fileCheck: '',
        folder: '/health_supervision',
      },
      // 体检记录导入form
      peRecordImportFormRules: {
        organName: [
          { required: true, message: '请选择体检机构', trigger: ['blur'] },
          { validator: this.validatorOrganName, trigger: ['blur'] },
        ],
      },
      // 导入记录查询form
      importRecordQueryForm: {
        currentPage: 1,
        pageSize: 7,
        importDateStart: '',
        importDateEnd: '',
        analysisProcessList: [],
      },
      // 导入数量提醒
      importPeRecordNum: undefined,
      importRecordQueryFormExp: {
        tableList: [],
        total: 0,
      },
    }
  },
  created() {
    // this.$route.query.employerUuid
    // if (this.$route.query.employerUuid != undefined) {
      // this.peRecordListForm.employerUuid = 'A83124D0BDFA449FB355E98ED974459F'
    // }
  },
  activated() {
    if (this.$route.params.fresh) this.queryPeRecordList(1)
  },
  mounted() {
    this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('3052', data => {
      this.peRecordListFormExp.mainInspectionConclusionOption = data['3052']
    })
    this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('3049', data => {
      this.peRecordListFormExp.postStateOption = data['3049']
    })
    this.queryPeRecordList(1)
  },
  computed: {},
  methods: {
    //查询
    queryPeRecordList(currentPage) {
      this.peRecordListForm.currentPage = currentPage
      let data = {
        currentPage: this.peRecordListForm.currentPage,
        employerUuid: this.peRecordListForm.employerUuid,
        staffNameLike: this.peRecordListForm.staffNameLike,
        cardNo: this.peRecordListForm.cardNo,
        peCodeLike: this.peRecordListForm.peCodeLike,
        typeList: this.peRecordListForm.peTypeList,
        organNameLike: this.peRecordListForm.organNameLike,
        peBeginDate: this.peRecordListForm.peBeginDate,
        peEndDate: this.peRecordListForm.peEndDate,
        conclusionCodeList: this.peRecordListForm.conclusionCodeList,
        postStateList: this.peRecordListForm.postStateList,
        ifExistence: this.peRecordListForm.ifExistence,
        orderBys: this.peRecordListForm.orderBys,
        orderByName: this.peRecordListForm.orderByName,
        sortType: this.peRecordListForm.sortType,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/health/surveillance/getPeRecordList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.peRecordListFormExp.tableList = data.peRecordList
            this.peRecordListFormExp.pageSize = data.pageSize
            this.peRecordListFormExp.total = data.peRecordCount
            this.importPeRecordNum = data.importPeRecordNum
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
    mainInspectionColor(mainInspection) {
      if (this.$zwxBase.verifyIsNotBlank(mainInspection)) {
        if (mainInspection == '职业禁忌证') {
          return 'color:#9719BB'
        }
        if (mainInspection == '疑似职业病') {
          return 'color:#FF1A21'
        }
      }
      return '-'
    },
    peTypeFormatter(row, column, cellValue) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        switch (cellValue) {
          case 1:
            return '职业健康体检'
          case 2:
            return '放射人员健康检查'
          case 3:
            return '福利体检'
          default:
        }
      }
      return '-'
    },
    //选择指定列进行排序
    changeTableSort(column) {
      if (this.$zwxBase.verifyIsNotBlank(column.order)) {
        //排序规则不为空
        if (column.prop == 'staffName') {
          this.peRecordListForm.orderByName = 'staffName'
        }
        if (column.prop == 'peCode') {
          this.peRecordListForm.orderByName = 'peCode'
        }
        if (column.prop == 'organName') {
          this.peRecordListForm.orderByName = 'organName'
        }
        if (column.prop == 'peDate') {
          this.peRecordListForm.orderByName = 'peDate'
        }
        if (column.prop == 'peType') {
          this.peRecordListForm.orderByName = 'peType'
        }
        if (column.prop == 'postStateStr') {
          this.peRecordListForm.orderByName = 'postStateStr'
        }
        if (column.order == 'ascending') {
          this.peRecordListForm.sortType = 0
        }
        if (column.order == 'descending') {
          this.peRecordListForm.sortType = 1
        }
      } else {
        this.peRecordListForm.orderByName = ''
        this.peRecordListForm.sortType = ''
      }
      this.queryPeRecordList(1)
    },
    /**
     * 编辑体检记录
     */
    editPeRecord(row) {
      this.$router.push({
        name: 'PeRecordEdit',
        params: { employerUuid: this.peRecordListForm.employerUuid, rid: row.rid },
      })
    },
    /**
     * 删除体检记录
     */
    deletePeRecord(row) {
      this.$system.msgbox('', '提示', '是否确认删除？', '确定', '取消', () => {
        let data = {
          rid: row.rid,
        }
        this.$emit('loading', true)
        this.$system.postJson(
          this.api + '/oh/health/surveillance/deletePeRecord-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.queryPeRecordList(1)
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$notify.info({ title: '错误', message: data.mess })
            }
          },
          () => {
            this.$emit('loading', false)
            this.$notify.error({ title: '错误', message: '网络连接失败' })
          }
        )
      })
    },
    /**
     * 查看体检记录详情
     */
    detail(row) {
      this.$router.push({
        name: 'PeRecordDetail',
        params: { employerUuid: this.employerUuid, rid: row.uuid        },
      })
    },
    /**
     * 添加
     */
    add() {
      this.$router.push({
        name: 'PeRecordEdit',
        params: { employerUuid: this.peRecordListForm.employerUuid, rid: '' },
      })
    },
    /**
     * 下载体检导入模板
     */
    downloadImportTemplate() {
      this.$system.downloadUrlFile('体检记录导入模板', this.$store.state.api + this.peImportTempPath)
    },
    /**
     * 体检记录导入
     */
    peRecordImport() {
      this.$refs.peRecordImportDialogRef.show(true)
    },
    validatorOrganName(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value) && value.length < 5) {
        return callback(new Error('请至少输入5个字符'))
      }
      return callback()
    },
    /**
     * 体检机构改变回调
     */
    peOrganSelectChange(value) {
      this.peRecordImportForm.organId = value.rid
      this.peRecordImportForm.organName = value.unitName
      this.peRecordImportForm.organCreditCode = value.creditCode
    },
    /**
     * 体检记录导入弹框确认
     */
    peRecordImportDialogDetermine() {
      this.$refs.peRecordImportForm.validate(valid => {
        if (valid) {
          if (this.$zwxBase.verifyIsBlank(this.peRecordImportForm.organCreditCode)) {
            this.$system.notify('警告', '请点击体检机构输入框上的放大镜进行查询并选择对应的体检机构', 'warning')
            return
          }
          this.generateFilesUid()
        }
      })
    },
    /**
     * 体检记录导入弹框取消
     */
    peRecordImportDialogCancel() {
      this.$refs.peRecordImportDialogRef.show(false)
    },
    /**
     * 体检记录导入弹框关闭回调
     */
    peRecordImportDialogClose() {
      this.peRecordImportForm.importFileList = []
      this.peRecordImportForm.organId = ''
      this.peRecordImportForm.organName = ''
      this.peRecordImportForm.organCreditCode = ''
      this.peRecordImportForm.fileName = ''
      this.peRecordImportForm.filePath = ''
      this.peRecordImportForm.fileCheck = ''
    },
    /**
     * 体检记录导入文件选择回调
     */
    peRecordImportFileList(showList) {
      this.peRecordImportForm.importFileList = []
      showList.forEach(item => {
        let flag = this.peRecordImportForm.importFileList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.peRecordImportForm.importFileList.push({
          fileName: item,
          check: false,
        })
      })
      this.peRecordImportForm.fileName = this.peRecordImportForm.importFileList[0].fileName
      this.peRecordImportForm.fileCheck = false
    },
    /**
     * 体检记录导入文件鼠标悬浮回调
     */
    peRecordImportMouseenter() {
      this.peRecordImportForm.fileCheck = true
    },
    /**
     * 体检记录导入文件鼠标移除回调
     */
    peRecordImportMouseleave() {
      this.peRecordImportForm.fileCheck = false
    },
    /**
     * 体检记录导入文件删除回调
     */
    delPeRecordImportFile() {
      let fileName = this.peRecordImportForm.importFileList[0].fileName
      this.peRecordImportForm.importFileList.splice(0, 1)
      this.$refs.fileUploadRef.deleteIndex(0)
      let fileList = this.$refs.fileUploadRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.fileUploadRef.deleteIndex(j)
            fileList = this.$refs.fileUploadRef.showFileList || []
            continue
          }
        }
      }
      this.peRecordImportForm.fileName = ''
    },
    /**
     * 获取文件上传uuid
     */
    generateFilesUid() {
      let data = {
        employerUuid: this.peRecordListForm.employerUuid,
        folder: this.peRecordImportForm.folder,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs.fileUploadRef.submit(data.annexUploadUid)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 体检记录导入文件上传成功回调
     */
    peRecordImportFileUploadSuccess(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.peRecordImportForm.importFileList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.peRecordImportForm.importFileList) {
          for (let j in fileList) {
            if (this.peRecordImportForm.importFileList[i].fileName === fileList[j].fileName) {
              this.peRecordImportForm.importFileList[i].filePath = fileList[j].filePath
            }
          }
        }
        this.peRecordImportForm.filePath = this.peRecordImportForm.importFileList[0].filePath
      }
      // 保存体检数据导入记录
      this.savePeImportRecord()
    },
    /**
     * 保存体检数据导入记录
     */
    savePeImportRecord() {
      let data = {
        employerUuid: this.peRecordListForm.employerUuid,
        organId: this.peRecordImportForm.organId,
        organName: this.peRecordImportForm.organName,
        organCreditCode: this.peRecordImportForm.organCreditCode,
        fileName: this.peRecordImportForm.fileName,
        filePath: this.peRecordImportForm.filePath,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/health/surveillance/addOrModifyPeImportRecord-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$refs.peRecordImportDialogRef.show(false)
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 打开导入记录弹框
     */
    showPeImportRecordDialog() {
      this.$refs.importRecordQueryDialogRef.show(true)
      this.queryImportRecordList(1)
    },
    /**
     * 查询导入记录
     */
    queryImportRecordList(currentPage) {
      this.importRecordQueryForm.currentPage = currentPage
      let data = {
        ...this.importRecordQueryForm,
        employerUuid: this.peRecordListForm.employerUuid,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/health/surveillance/getPeImportRecordList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.importRecordQueryFormExp.tableList = data.tdPeImportRecordList || []
            this.importRecordQueryFormExp.total = data.tdPeImportRecordCount
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 日期格式化
     */
    dateFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    /**
     * 下载错误报告文件
     */
    downloadErrorFile(row) {
      if (this.$zwxBase.verifyIsBlank(row.errorReportPath)) {
        this.$system.notify('错误', '未生成错误报告', 'error')
        return
      }
      let fileName = row.fileName.replace('.', '错误数据报告.')
      this.$system.downloadUrlFile(fileName, this.$store.state.api + row.errorReportPath)
    },
    /**
     * 取消导入记录弹框
     */
    cancelImportRecordQueryDialog() {
      this.$refs.importRecordQueryDialogRef.show(false)
    },
    /**
     * 关闭导入记录弹框回调
     */
    closeImportRecordQueryDialog() {
      this.importRecordQueryForm.importDateStart = ''
      this.importRecordQueryForm.importDateEnd = ''
      this.importRecordQueryForm.analysisProcessList = []
      this.importRecordQueryFormExp.tableList = []
      this.importRecordQueryFormExp.total = 0
    },
  },
}
</script>
<style lang="less" scoped>
.pe-record-management {
  .pe-record-import-dialog {
    /deep/ .organ-select .zwx-input {
      width: 398px !important;
    }

    .dialog-footer {
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      margin-left: 20px;
    }

    /deep/ .el-dialog {
      width: 520px !important;
      min-width: 520px !important;
    }

    /deep/ .el-upload-dragger {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 488px;
      height: 228px;
    }

    .el-upload-text {
      margin-top: 18px;
    }

    .show-file-body {
      width: 100%;
      padding: 24px;
    }

    .show-file-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: 102px;
      margin-bottom: 24px;
      background: #f9fbfd;
      padding-left: 36px;
      padding-right: 20px;
    }

    .file-name-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 72px);
      font-size: 14px;
      font-weight: 400;
      color: #1f2d3d;
      margin-left: 24px;
    }

    .show-error-file-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: 102px;
      background: #fff5f5;
      border: 1px solid #f6cccc;
      padding-left: 36px;
      padding-right: 20px;
    }
  }

  /deep/ .import-record-query-dialog .el-dialog {
    width: 851px !important;
    min-width: 851px !important;
  }

  .import-record-query-dialog .import-record-query-body {
    height: 339px;
  }

  .import-tip {
    font-size: 10px;
    margin-left: 10px;
    color: #d97c09;
  }
}
</style>

<style lang="less"></style>
